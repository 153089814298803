<app-light-header [class.d-none]="mode"></app-light-header>
<div *ngIf="loadedError; else noError" class="pb-6">
    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
        <div class="text-center pt-7">
            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
        </div>
    </div>
</div>
<ng-template #noError>
    <div
        class="container bg-light position-relative app-match-detail-page"
        [class.mode-set]="isMode"
        [class.mode-set-main]="isModeMain"
    >
        <ng-container *ngIf="!this.isCuptree; else cuptree">
            <ng-container *ngIf="$br | async as ids; else loading">
                <app-match-detail
                    *ngIf="ids.br"
                    [id]="ids.br[0]"
                    (onLoad)="loadedData($event)"
                    [noAdds]="true"
                    [modeType]="mode"
                    [disableAuth]="isSecret"
                    [fullScreen]="true"
                    [widgetUrl]="true"
                ></app-match-detail>

                <div class="tab-pane" *ngIf="!ids.br" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                    <div class="text-center py-7">
                        <svg class="icon icon--64 mb-4">
                            <use xlink:href="#icon-soccer" />
                        </svg>
                        <h4 class="font-weight-bold">{{ 'web.match_widget_no_data' | translate }}</h4>

                        <p class="mb-6">{{ 'web.no_wm_data_found' | translate }}</p>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #cuptree>
            <ng-container *ngIf="data; else loading">
                <app-cup-tree-page
                    [subTournamentId]="data.sub_tournament_id"
                    [seasonId]="data.season_id"
                ></app-cup-tree-page> </ng-container
        ></ng-template>

        <ng-template #loading>
            <app-page-loader></app-page-loader>
        </ng-template>
    </div>
</ng-template>
<section class="d-none">
    <app-footer></app-footer>
</section>
