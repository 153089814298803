/* eslint-disable @angular-eslint/component-selector */
/**
 *  Homepage component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Data, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { BaseComponent } from '@pages/base.component';
import { PageService } from '@services/page.service';
import { InfoService } from '@services/info.service';
import { ClientService } from '@services/client.service';
import { LangService } from '@services/lang.service';


import { Store } from '@ngxs/store';
import { MatchData, MatchDetailDisplayMode, Team } from '@interfaces/match-data.interface';
import { UtilsService } from '@services/utils.service';
import { MatchService } from '@services/match.service';

import { UpdateMarkets } from '@/store/actions/bets.action';

@Component({
    selector: 'match-widget',
    templateUrl: './match-widget.component.html',
    styles: [],
})
export class MatchWidgetComponent extends PageMixin(BaseComponent) implements OnInit {
    private subscriber: Subscription;

    public $br: Observable<Data>;

    public data: MatchData<[]>;

    public mode: MatchDetailDisplayMode;

    public isSecret: boolean = false;

    public constructor(
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        private router: ActivatedRoute,
        private store: Store,
        private match: MatchService,
    ) {
        super(page, title, meta, route, info);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        $('body').removeClass('loader-active');

        this.router.queryParamMap.subscribe((param: ParamMap) => {
            this.mode = param.get('mode') as MatchDetailDisplayMode;
            this.store.dispatch(new UpdateMarkets());
            this.$br = this.router.data;

            if (param.has('secret')) {
                this.isSecret = true;
            }

            if (this.isCuptree) {
                this.$br.subscribe((val) => {
                    if (val.br) {
                        this.getMatch(val.br[0]);
                    } else {
                        this.error();
                    }
                });
            }

            if (this.isMode || this.isModeMain) {
                UtilsService.addClass(document.getElementsByTagName('body')[0], 'match-widget--mode');
            }
        });

        UtilsService.addClass(document.getElementsByTagName('body')[0], 'match-widget');
    }

    public loadedData(data: MatchData): void {
        this.data = data;
        this.lang.getLang().then((): Promise<any> => this.createTitle());
        this.finished(true);
    }

    get isMode(): boolean {
        return !!this.mode && this.mode !== 'tracker' && this.mode !== 'video';
    }

    get isModeMain(): boolean {
        return !!this.mode && (this.mode === 'tracker' || this.mode === 'video');
    }

    get isCuptree(): boolean {
        return this.mode === 'cuptree';
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_match').subscribe((): void => {
            const participants: string[] = [];

            this.data.participants.forEach((val: Team): void => {
                if (val.name_short !== null) {
                    participants.push(val.name_short!);
                } else {
                    participants.push(val.name);
                }
            });

            const names = participants.join(' - ');
            const trans = this.translate.instant('web.title_match');
            const sport = this.translate.instant(`sport.${this.data.sport_code_name}`);
            const desc = this.translate.instant('web.description_match');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} | ${names} ${trans} | ${client}`,
                    description: `${names} - LIVE - ${sport} ${desc}`,
                },
                true,
            );
        });
    }

    private getMatch(id: number): void {
        this.match.getMatch(id).then((m) => {
            m.subscribe((match) => {
                this.data = match;
            });
        }).catch(() => {
            this.error();
        });
    }

    public trackByFn(index: number, item: Record<any, any>): number {
        return item.id;
    }
}
