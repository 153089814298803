import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';

import { ComponentModule } from '@components/component.module';


import { MatchWidgetRoutingModule } from './match-widget-routing.module';
import { MatchWidgetComponent } from './match-widget.component';


@NgModule({
    declarations: [MatchWidgetComponent],
    imports: [CommonModule, SharedModule, MatchWidgetRoutingModule, ComponentModule],
})
export class MatchWidgetModule { }
